import { TCategory } from '@/common/models';
import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetPublicCategoriesParams = {
  type: string;
  academyId: string;
};

export type TGetPublicCategoriesMaterials = {
  params?: TGetPublicCategoriesParams;
};

export type TGetPublicCategoriesResponse = TCommonResponse & {
  data: TCategory[];
};

// FUNCTION

export const getPublicCategories = async ({
  params,
}: TGetPublicCategoriesMaterials): Promise<TGetPublicCategoriesResponse> => {
  const response = await ApiService.get(`/v1/api/categories`, { params });
  return response.data;
};
