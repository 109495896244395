import ApiService from '@/services/api';

// TYPES

export type TRequestTimeOffStatusPaths = {
  id: string | number;
};
export type TRequestTimeOffStatusBody = unknown;

export type TRequestTimeOffStatusMaterials = {
  paths?: TRequestTimeOffStatusPaths;
  body?: TRequestTimeOffStatusBody;
};

export type TRequestTimeOffStatusResponse = unknown;

// FUNCTION

export const requestTimeOffStatus = async ({
  paths,
  body,
}: TRequestTimeOffStatusMaterials): Promise<TRequestTimeOffStatusResponse> => {
  const response = await ApiService.patch(`/v1/api/admin/time-off/${paths?.id}`, body);
  return response.data;
};
