import { createReducer } from 'deox';

import { TDeleteTimeOffResponse, TGetTimeOffsResponse, TRequestTimeOffStatusResponse } from '@/services/api/time-off';
import { deleteTimeOffAction, getTimeOffsAction, requestTimeOffStatusAction } from '@/redux/actions';
import { deleteTimeOffUpdateState } from './delete-time-off';
import { getTimeOffsUpdateState } from './get-time-offs';
import { requestTimeOffStatusUpdateState } from './request-time-off-status';

export type TTimeOffState = {
  deleteTimeOffResponse?: TDeleteTimeOffResponse;
  getTimeOffsResponse?: TGetTimeOffsResponse;
  requestTimeOffStatusResponse?: TRequestTimeOffStatusResponse;
};

const initialState: TTimeOffState = {
  deleteTimeOffResponse: undefined,
  getTimeOffsResponse: undefined,
  requestTimeOffStatusResponse: undefined,
};

const TimeOffReducer = createReducer(initialState, (handleAction) => [
  handleAction(deleteTimeOffAction.success, deleteTimeOffUpdateState),
  handleAction(getTimeOffsAction.success, getTimeOffsUpdateState),
  handleAction(requestTimeOffStatusAction.success, requestTimeOffStatusUpdateState),
]);

export default TimeOffReducer;
