import { TReportState } from '@/redux/reducers/report';
import { TGetReportBenefitLossSuccess } from '@/redux/actions/report';

export const getReportBenefitLossUpdateState = (
  state: TReportState,
  action: TGetReportBenefitLossSuccess,
): TReportState => ({
  ...state,
  getReportBenefitLossResponse: action.payload.response,
});
