import ApiService from '@/services/api';

// TYPES

export type TGetTagsParams = {
  name?: string;
};

export type TGetTagsMaterials = {
  params?: TGetTagsParams;
};

export type TGetTagsResponse = unknown;

// FUNCTION

export const getTags = async ({ params }: TGetTagsMaterials): Promise<TGetTagsResponse> => {
  const response = await ApiService.get(`/v1/api/tags`, { params });
  return response.data;
};
