import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getReportBenefitLossAction } from '@/redux/actions';
import { getReportBenefitLoss, TGetReportBenefitLossResponse } from '@/services/api';

// FUNCTION

export function* getReportBenefitLossSaga(action: ActionType<typeof getReportBenefitLossAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getReportBenefitLoss, materials);
    const getReportBenefitLossResponse: TGetReportBenefitLossResponse = response as TGetReportBenefitLossResponse;
    yield put(getReportBenefitLossAction.success(getReportBenefitLossResponse));
    successCallback?.(getReportBenefitLossResponse);
  } catch (err) {
    yield put(getReportBenefitLossAction.failure(err));
    failedCallback?.(err);
  }
}
