import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { updatePlayerMemberCardAction } from '@/redux/actions';
import { updatePlayerMemberCard, TUpdatePlayerMemberCardResponse } from '@/services/api';

// FUNCTION

export function* updatePlayerMemberCardSaga(
  action: ActionType<typeof updatePlayerMemberCardAction.request>,
): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(updatePlayerMemberCard, materials);
    const updatePlayerMemberCardResponse: TUpdatePlayerMemberCardResponse = response as TUpdatePlayerMemberCardResponse;
    yield put(updatePlayerMemberCardAction.success(updatePlayerMemberCardResponse));
    successCallback?.(updatePlayerMemberCardResponse);
  } catch (err) {
    yield put(updatePlayerMemberCardAction.failure(err));
    failedCallback?.(err);
  }
}
