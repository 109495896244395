import { createActionCreator } from 'deox';

import {
  TRemovePlayerFromClassMaterials,
  TRemovePlayerFromClassResponse,
} from '@/services/api/class/remove-player-from-class';

// CONSTANTS

export enum ERemovePlayerFromClassAction {
  REMOVE_PLAYER_FROM_CLASS = 'REMOVE_PLAYER_FROM_CLASS',
  REMOVE_PLAYER_FROM_CLASS_REQUEST = 'REMOVE_PLAYER_FROM_CLASS_REQUEST',
  REMOVE_PLAYER_FROM_CLASS_SUCCESS = 'REMOVE_PLAYER_FROM_CLASS_SUCCESS',
  REMOVE_PLAYER_FROM_CLASS_FAILED = 'REMOVE_PLAYER_FROM_CLASS_FAILED',
}

// TYPES

export type TRemovePlayerFromClassRequest = {
  type: ERemovePlayerFromClassAction.REMOVE_PLAYER_FROM_CLASS_REQUEST;
  payload: {
    materials: TRemovePlayerFromClassMaterials;
    successCallback?: (response: TRemovePlayerFromClassResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TRemovePlayerFromClassSuccess = {
  type: ERemovePlayerFromClassAction.REMOVE_PLAYER_FROM_CLASS_SUCCESS;
  payload: { response: TRemovePlayerFromClassResponse };
};

export type TRemovePlayerFromClassFailed = { type: ERemovePlayerFromClassAction.REMOVE_PLAYER_FROM_CLASS_FAILED };

// FUNCTION

export const removePlayerFromClassAction = {
  request: createActionCreator(
    ERemovePlayerFromClassAction.REMOVE_PLAYER_FROM_CLASS_REQUEST,
    (resolve) =>
      (
        materials: TRemovePlayerFromClassMaterials,
        successCallback?: (response: TRemovePlayerFromClassResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TRemovePlayerFromClassRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ERemovePlayerFromClassAction.REMOVE_PLAYER_FROM_CLASS_SUCCESS,
    (resolve) =>
      (response: TRemovePlayerFromClassResponse): TRemovePlayerFromClassSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ERemovePlayerFromClassAction.REMOVE_PLAYER_FROM_CLASS_FAILED,
    (resolve) =>
      (error: unknown): TRemovePlayerFromClassFailed =>
        resolve({ error }),
  ),
};
