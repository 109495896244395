import { createActionCreator } from 'deox';

import { TGetReportOrdersMaterials, TGetReportOrdersResponse } from '@/services/api/report/get-report-orders';

// CONSTANTS

export enum EGetReportOrdersAction {
  GET_REPORT_ORDERS = 'GET_REPORT_ORDERS',
  GET_REPORT_ORDERS_REQUEST = 'GET_REPORT_ORDERS_REQUEST',
  GET_REPORT_ORDERS_SUCCESS = 'GET_REPORT_ORDERS_SUCCESS',
  GET_REPORT_ORDERS_FAILED = 'GET_REPORT_ORDERS_FAILED',
}

// TYPES

export type TGetReportOrdersRequest = {
  type: EGetReportOrdersAction.GET_REPORT_ORDERS_REQUEST;
  payload: {
    materials: TGetReportOrdersMaterials;
    successCallback?: (response: TGetReportOrdersResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetReportOrdersSuccess = {
  type: EGetReportOrdersAction.GET_REPORT_ORDERS_SUCCESS;
  payload: { response: TGetReportOrdersResponse };
};

export type TGetReportOrdersFailed = { type: EGetReportOrdersAction.GET_REPORT_ORDERS_FAILED };

// FUNCTION

export const getReportOrdersAction = {
  request: createActionCreator(
    EGetReportOrdersAction.GET_REPORT_ORDERS_REQUEST,
    (resolve) =>
      (
        materials: TGetReportOrdersMaterials,
        successCallback?: (response: TGetReportOrdersResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetReportOrdersRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetReportOrdersAction.GET_REPORT_ORDERS_SUCCESS,
    (resolve) =>
      (response: TGetReportOrdersResponse): TGetReportOrdersSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetReportOrdersAction.GET_REPORT_ORDERS_FAILED,
    (resolve) =>
      (error: unknown): TGetReportOrdersFailed =>
        resolve({ error }),
  ),
};
