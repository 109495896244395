import ApiService from '@/services/api';

// TYPES

export type TRemovePlayerFromClassPaths = {
  id: string | number;
};
export type TRemovePlayerFromClassParams = unknown;

export type TRemovePlayerFromClassMaterials = {
  paths?: TRemovePlayerFromClassPaths;
  params?: TRemovePlayerFromClassParams;
};

export type TRemovePlayerFromClassResponse = unknown;

// FUNCTION

export const removePlayerFromClass = async ({
  paths,
  params,
}: TRemovePlayerFromClassMaterials): Promise<TRemovePlayerFromClassResponse> => {
  const response = await ApiService.delete(`/v1/api/admin/playerclass/${paths?.id}`, { params });
  return response.data;
};
