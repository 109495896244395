import React from 'react';

import { TIconProps } from './Icon.types';

const Svg: React.FC<TIconProps> = ({ color }) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0001 21.3369C16.9707 21.3369 21.0001 17.3075 21.0001 12.3369C21.0001 7.36635 16.9707 3.33691 12.0001 3.33691C7.02956 3.33691 3.00012 7.36635 3.00012 12.3369C3.00012 17.3075 7.02956 21.3369 12.0001 21.3369Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 18.1494C12.5178 18.1494 12.9375 17.7297 12.9375 17.2119C12.9375 16.6941 12.5178 16.2744 12 16.2744C11.4822 16.2744 11.0625 16.6941 11.0625 17.2119C11.0625 17.7297 11.4822 18.1494 12 18.1494Z"
        fill={color}
      />
      <path
        d="M12 13.8369V13.0869C12.5192 13.0869 13.0267 12.933 13.4584 12.6445C13.8901 12.3561 14.2265 11.9461 14.4252 11.4665C14.6239 10.9868 14.6758 10.459 14.5746 9.9498C14.4733 9.4406 14.2233 8.97287 13.8562 8.60576C13.489 8.23865 13.0213 7.98864 12.5121 7.88735C12.0029 7.78607 11.4751 7.83805 10.9955 8.03673C10.5158 8.23541 10.1058 8.57186 9.81739 9.00354C9.52895 9.43522 9.375 9.94274 9.375 10.4619"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Svg;
