import { all, takeLatest } from 'redux-saga/effects';

import { deleteTimeOffAction, getTimeOffsAction, requestTimeOffStatusAction } from '@/redux/actions';

import { deleteTimeOffSaga } from './delete-time-off';
import { getTimeOffsSaga } from './get-time-offs';
import { requestTimeOffStatusSaga } from './request-time-off-status';

export default function* root(): Generator {
  yield all([
    takeLatest(deleteTimeOffAction.request.type, deleteTimeOffSaga),
    takeLatest(getTimeOffsAction.request.type, getTimeOffsSaga),
    takeLatest(requestTimeOffStatusAction.request.type, requestTimeOffStatusSaga),
  ]);
}
