import { createActionCreator } from 'deox';

import {
  TUpdatePlayerMemberCardMaterials,
  TUpdatePlayerMemberCardResponse,
} from '@/services/api/player/update-player-member-card';

// CONSTANTS

export enum EUpdatePlayerMemberCardAction {
  UPDATE_PLAYER_MEMBER_CARD = 'UPDATE_PLAYER_MEMBER_CARD',
  UPDATE_PLAYER_MEMBER_CARD_REQUEST = 'UPDATE_PLAYER_MEMBER_CARD_REQUEST',
  UPDATE_PLAYER_MEMBER_CARD_SUCCESS = 'UPDATE_PLAYER_MEMBER_CARD_SUCCESS',
  UPDATE_PLAYER_MEMBER_CARD_FAILED = 'UPDATE_PLAYER_MEMBER_CARD_FAILED',
}

// TYPES

export type TUpdatePlayerMemberCardRequest = {
  type: EUpdatePlayerMemberCardAction.UPDATE_PLAYER_MEMBER_CARD_REQUEST;
  payload: {
    materials: TUpdatePlayerMemberCardMaterials;
    successCallback?: (response: TUpdatePlayerMemberCardResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdatePlayerMemberCardSuccess = {
  type: EUpdatePlayerMemberCardAction.UPDATE_PLAYER_MEMBER_CARD_SUCCESS;
  payload: { response: TUpdatePlayerMemberCardResponse };
};

export type TUpdatePlayerMemberCardFailed = { type: EUpdatePlayerMemberCardAction.UPDATE_PLAYER_MEMBER_CARD_FAILED };

// FUNCTION

export const updatePlayerMemberCardAction = {
  request: createActionCreator(
    EUpdatePlayerMemberCardAction.UPDATE_PLAYER_MEMBER_CARD_REQUEST,
    (resolve) =>
      (
        materials: TUpdatePlayerMemberCardMaterials,
        successCallback?: (response: TUpdatePlayerMemberCardResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdatePlayerMemberCardRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpdatePlayerMemberCardAction.UPDATE_PLAYER_MEMBER_CARD_SUCCESS,
    (resolve) =>
      (response: TUpdatePlayerMemberCardResponse): TUpdatePlayerMemberCardSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpdatePlayerMemberCardAction.UPDATE_PLAYER_MEMBER_CARD_FAILED,
    (resolve) =>
      (error: unknown): TUpdatePlayerMemberCardFailed =>
        resolve({ error }),
  ),
};
