import React from 'react';

import { TIconProps } from './Icon.types';

const Svg: React.FC<TIconProps> = ({ color }) => {
  return (
    <svg width="38" height="39" viewBox="0 0 38 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.7434 1.84361C18.3351 0.935703 19.6649 0.935703 20.2566 1.8436L25.6035 10.0468C25.9218 10.5352 26.4008 10.897 26.9577 11.0696L36.175 13.9265C37.1587 14.2314 37.5496 15.414 36.9415 16.2451L30.9996 24.3649C30.6729 24.8114 30.5036 25.3536 30.518 25.9067L30.7833 36.049C30.8111 37.1121 29.7546 37.8657 28.7585 37.4932L19.8756 34.1719C19.3109 33.9608 18.6891 33.9608 18.1244 34.1719L9.2415 37.4932C8.24544 37.8657 7.18887 37.1121 7.21668 36.049L7.48198 25.9067C7.49644 25.3536 7.32706 24.8114 7.00036 24.3649L1.05853 16.2451C0.450404 15.414 0.84134 14.2314 1.82496 13.9265L11.0423 11.0696C11.5992 10.897 12.0782 10.5352 12.3965 10.0468L17.7434 1.84361Z"
        fillOpacity="0.05"
        fill={color}
        stroke={color}
      />
    </svg>
  );
};

export default Svg;
