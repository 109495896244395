import { TCommonResponse, THeaderBranchIds } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetReportBenefitLossParams = {
  filterUnit?: string;
  fromDate?: number;
  toDate?: number;
};

export type TGetReportBenefitLossMaterials = {
  params?: TGetReportBenefitLossParams;
  headers?: THeaderBranchIds;
};

export type TReportBenefitLossData = {
  at_date: number;
  at_month: number;
  at_year: number;
  total_amount: number;
  total_percent: number;
};

export type TProfitLossPerTime = {
  expense_per_time_list: TReportBenefitLossData[];
  profit_loss_per_time_list: TReportBenefitLossData[];
  revenue_per_time_list: TReportBenefitLossData[];
};

export type TGetReportBenefitLossResponse = TCommonResponse & {
  data: {
    profit_loss_per_time: TProfitLossPerTime;
    expense_percent: number;
    profit_loss_percent: number;
    revenue_percent: number;
    total_amount_expense: number;
    total_amount_revenue: number;
    total_profit_loss: number;
  };
};

// FUNCTION

export const getReportBenefitLoss = async ({
  params,
  headers,
}: TGetReportBenefitLossMaterials): Promise<TGetReportBenefitLossResponse> => {
  const response = await ApiService.get(`/v1/api/profit-and-loss/${params?.filterUnit}`, { params, headers });
  return response.data;
};
