import ApiService from '@/services/api';

// TYPES

export type TAddPlayerToClassParams = unknown;
export type TAddPlayerToClassBody = unknown;

export type TAddPlayerToClassMaterials = {
  params?: TAddPlayerToClassParams;
  body?: TAddPlayerToClassBody;
};

export type TAddPlayerToClassResponse = unknown;

// FUNCTION

export const addPlayerToClass = async ({
  params,
  body,
}: TAddPlayerToClassMaterials): Promise<TAddPlayerToClassResponse> => {
  const response = await ApiService.post(`/v1/api/admin/playerclass`, body, { params });
  return response.data;
};
