import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { requestTimeOffStatusAction } from '@/redux/actions';
import { requestTimeOffStatus, TRequestTimeOffStatusResponse } from '@/services/api';

// FUNCTION

export function* requestTimeOffStatusSaga(action: ActionType<typeof requestTimeOffStatusAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(requestTimeOffStatus, materials);
    const requestTimeOffStatusResponse: TRequestTimeOffStatusResponse = response as TRequestTimeOffStatusResponse;
    yield put(requestTimeOffStatusAction.success(requestTimeOffStatusResponse));
    successCallback?.(requestTimeOffStatusResponse);
  } catch (err) {
    yield put(requestTimeOffStatusAction.failure(err));
    failedCallback?.(err);
  }
}
