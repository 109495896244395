import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getTagsAction } from '@/redux/actions';
import { getTags, TGetTagsResponse } from '@/services/api';

// FUNCTION

export function* getTagsSaga(action: ActionType<typeof getTagsAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getTags, materials);
    const getTagsResponse: TGetTagsResponse = response as TGetTagsResponse;
    yield put(getTagsAction.success(getTagsResponse));
    successCallback?.(getTagsResponse);
  } catch (err) {
    yield put(getTagsAction.failure(err));
    failedCallback?.(err);
  }
}
