import React from 'react';

import { TIconProps } from './Icon.types';

const Svg: React.FC<TIconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="190"
      height="166"
      viewBox="0 0 190 166"
      id="excel"
    >
      <defs>
        <rect id="a" width="142" height="166" rx="8" />
        <rect id="d" width="71" height="42" y="41" />
        <rect id="h" width="71" height="42" x="71" y="41" />
        <rect id="l" width="71" height="42" y="-1" />
        <rect id="p" width="71" height="42" x="71" y="-1" />
        <rect id="t" width="71" height="42" y="83" />
        <rect id="x" width="71" height="42" x="71" y="83" />
        <rect id="B" width="71" height="42" y="125" />
        <rect id="F" width="71" height="42" x="71" y="125" />
        <rect id="I" width="142" height="166" rx="8" />
        <rect id="L" width="96" height="96" rx="8" />
        <rect id="R" width="96" height="98" y="-1" rx="8" />
        <filter id="f" width="102.8%" height="104.8%" x="-1.4%" y="-2.4%" filterUnits="objectBoundingBox">
          <feOffset dx="-1" in="SourceAlpha" result="shadowOffsetInner1" />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner1"
          />
          <feColorMatrix
            in="shadowInnerInner1"
            result="shadowMatrixInner1"
            values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.1 0"
          />
          <feGaussianBlur in="SourceAlpha" result="shadowBlurInner2" stdDeviation=".5" />
          <feOffset dy="1" in="shadowBlurInner2" result="shadowOffsetInner2" />
          <feComposite
            in="shadowOffsetInner2"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner2"
          />
          <feColorMatrix
            in="shadowInnerInner2"
            result="shadowMatrixInner2"
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.08 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixInner1" />
            <feMergeNode in="shadowMatrixInner2" />
          </feMerge>
        </filter>
        <filter id="j" width="143.7%" height="173.8%" x="-21.8%" y="-36.9%" filterUnits="objectBoundingBox">
          <feOffset dy="-1" in="SourceAlpha" result="shadowOffsetInner1" />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner1"
          />
          <feColorMatrix
            in="shadowInnerInner1"
            result="shadowMatrixInner1"
            values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.05 0"
          />
          <feGaussianBlur in="SourceAlpha" result="shadowBlurInner2" stdDeviation="10" />
          <feOffset dy="11" in="shadowBlurInner2" result="shadowOffsetInner2" />
          <feComposite
            in="shadowOffsetInner2"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner2"
          />
          <feColorMatrix
            in="shadowInnerInner2"
            result="shadowMatrixInner2"
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.14 0"
          />
          <feOffset dx="1" dy="1" in="SourceAlpha" result="shadowOffsetInner3" />
          <feComposite
            in="shadowOffsetInner3"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner3"
          />
          <feColorMatrix
            in="shadowInnerInner3"
            result="shadowMatrixInner3"
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.15 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixInner1" />
            <feMergeNode in="shadowMatrixInner2" />
            <feMergeNode in="shadowMatrixInner3" />
          </feMerge>
        </filter>
        <filter id="n" width="101.4%" height="102.4%" x="-.7%" y="-1.2%" filterUnits="objectBoundingBox">
          <feOffset dy="-1" in="SourceAlpha" result="shadowOffsetInner1" />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner1"
          />
          <feColorMatrix in="shadowInnerInner1" values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.15 0" />
        </filter>
        <filter id="r" width="102.8%" height="104.8%" x="-1.4%" y="-2.4%" filterUnits="objectBoundingBox">
          <feOffset dy="-1" in="SourceAlpha" result="shadowOffsetInner1" />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner1"
          />
          <feColorMatrix
            in="shadowInnerInner1"
            result="shadowMatrixInner1"
            values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.15 0"
          />
          <feOffset dx="2" in="SourceAlpha" result="shadowOffsetInner2" />
          <feComposite
            in="shadowOffsetInner2"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner2"
          />
          <feColorMatrix
            in="shadowInnerInner2"
            result="shadowMatrixInner2"
            values="0 0 0 0 0   0 0 0 0 0.709803922   0 0 0 0 0.37254902  0 0 0 0.63 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixInner1" />
            <feMergeNode in="shadowMatrixInner2" />
          </feMerge>
        </filter>
        <filter id="v" width="102.8%" height="104.8%" x="-1.4%" y="-2.4%" filterUnits="objectBoundingBox">
          <feOffset dx="-1" in="SourceAlpha" result="shadowOffsetInner1" />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner1"
          />
          <feColorMatrix
            in="shadowInnerInner1"
            result="shadowMatrixInner1"
            values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.7 0"
          />
          <feOffset dx="2" dy="1" in="SourceAlpha" result="shadowOffsetInner2" />
          <feComposite
            in="shadowOffsetInner2"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner2"
          />
          <feColorMatrix
            in="shadowInnerInner2"
            result="shadowMatrixInner2"
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.08 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixInner1" />
            <feMergeNode in="shadowMatrixInner2" />
          </feMerge>
        </filter>
        <filter id="z" width="101.4%" height="102.4%" x="-.7%" y="-1.2%" filterUnits="objectBoundingBox">
          <feOffset dy="-1" in="SourceAlpha" result="shadowOffsetInner1" />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner1"
          />
          <feColorMatrix
            in="shadowInnerInner1"
            result="shadowMatrixInner1"
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.12 0"
          />
          <feOffset dx="1" dy="1" in="SourceAlpha" result="shadowOffsetInner2" />
          <feComposite
            in="shadowOffsetInner2"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner2"
          />
          <feColorMatrix
            in="shadowInnerInner2"
            result="shadowMatrixInner2"
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.05 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixInner1" />
            <feMergeNode in="shadowMatrixInner2" />
          </feMerge>
        </filter>
        <filter id="D" width="102.8%" height="104.8%" x="-1.4%" y="-2.4%" filterUnits="objectBoundingBox">
          <feOffset dx="-1" in="SourceAlpha" result="shadowOffsetInner1" />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner1"
          />
          <feColorMatrix
            in="shadowInnerInner1"
            result="shadowMatrixInner1"
            values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.15 0"
          />
          <feOffset dx="1" in="SourceAlpha" result="shadowOffsetInner2" />
          <feComposite
            in="shadowOffsetInner2"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner2"
          />
          <feColorMatrix
            in="shadowInnerInner2"
            result="shadowMatrixInner2"
            values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.15 0"
          />
          <feOffset dx="2" dy="1" in="SourceAlpha" result="shadowOffsetInner3" />
          <feComposite
            in="shadowOffsetInner3"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner3"
          />
          <feColorMatrix
            in="shadowInnerInner3"
            result="shadowMatrixInner3"
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.08 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixInner1" />
            <feMergeNode in="shadowMatrixInner2" />
            <feMergeNode in="shadowMatrixInner3" />
          </feMerge>
        </filter>
        <filter id="H" width="102.8%" height="104.8%" x="-1.4%" y="-2.4%" filterUnits="objectBoundingBox">
          <feOffset dx="-1" in="SourceAlpha" result="shadowOffsetInner1" />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner1"
          />
          <feColorMatrix
            in="shadowInnerInner1"
            result="shadowMatrixInner1"
            values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.08 0"
          />
          <feOffset dx="2" dy="1" in="SourceAlpha" result="shadowOffsetInner2" />
          <feComposite
            in="shadowOffsetInner2"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner2"
          />
          <feColorMatrix
            in="shadowInnerInner2"
            result="shadowMatrixInner2"
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.01 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixInner1" />
            <feMergeNode in="shadowMatrixInner2" />
          </feMerge>
        </filter>
        <filter id="J" width="117%" height="117%" x="-8.5%" y="-8.5%" filterUnits="objectBoundingBox">
          <feGaussianBlur in="SourceGraphic" stdDeviation="3" />
        </filter>
        <filter id="Q" width="103.1%" height="103.1%" x="-1.6%" y="-1.5%" filterUnits="objectBoundingBox">
          <feGaussianBlur in="SourceAlpha" result="shadowBlurInner1" stdDeviation=".25" />
          <feOffset dx="-1" in="shadowBlurInner1" result="shadowOffsetInner1" />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner1"
          />
          <feColorMatrix
            in="shadowInnerInner1"
            result="shadowMatrixInner1"
            values="0 0 0 0 0.0199267733   0 0 0 0 0.65973108   0 0 0 0 0.312408742  0 0 0 1 0"
          />
          <feGaussianBlur in="SourceAlpha" result="shadowBlurInner2" stdDeviation=".5" />
          <feOffset dx="2" in="shadowBlurInner2" result="shadowOffsetInner2" />
          <feComposite
            in="shadowOffsetInner2"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner2"
          />
          <feColorMatrix
            in="shadowInnerInner2"
            result="shadowMatrixInner2"
            values="0 0 0 0 0   0 0 0 0 0.466666667   0 0 0 0 0.250980392  0 0 0 1 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixInner1" />
            <feMergeNode in="shadowMatrixInner2" />
          </feMerge>
        </filter>
        <filter id="S" width="141.7%" height="137.9%" x="-20.8%" y="-17%" filterUnits="objectBoundingBox">
          <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3" />
          <feColorMatrix
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0"
          />
          <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter2" />
          <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation=".5" />
          <feColorMatrix
            in="shadowBlurOuter2"
            result="shadowMatrixOuter2"
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="shadowMatrixOuter2" />
          </feMerge>
        </filter>
        <linearGradient id="c" x1="6.294%" x2="103.402%" y1="0%" y2="103.5%">
          <stop offset="0%" stopColor="#007438" />
          <stop offset="97.34%" stopColor="#008B44" />
        </linearGradient>
        <linearGradient id="g" x1="107.466%" x2="6.294%" y1="107.831%" y2="0%">
          <stop offset="0%" stopColor="#00D576" />
          <stop offset="97.026%" stopColor="#00A054" />
        </linearGradient>
        <linearGradient id="k" x1="6.294%" x2="107.466%" y1="0%" y2="107.831%">
          <stop offset="0%" stopColor="#007E42" />
          <stop offset="95.983%" stopColor="#009A50" />
        </linearGradient>
        <linearGradient id="o" x1="6.294%" x2="107.466%" y1="0%" y2="107.831%">
          <stop offset="0%" stopColor="#00AD61" />
          <stop offset="100%" stopColor="#00E18C" />
        </linearGradient>
        <linearGradient id="s" x1="107.466%" x2="9.303%" y1="107.831%" y2="3.207%">
          <stop offset="0%" stopColor="#003D20" />
          <stop offset="100%" stopColor="#004F2A" />
        </linearGradient>
        <linearGradient id="w" x1="107.466%" x2="9.303%" y1="107.831%" y2="3.207%">
          <stop offset="0%" stopColor="#00C165" />
          <stop offset="100%" stopColor="#008D46" />
        </linearGradient>
        <linearGradient id="A" x1="107.466%" x2="9.303%" y1="107.831%" y2="3.207%">
          <stop offset="0%" stopColor="#00522E" />
          <stop offset="100%" stopColor="#003B20" />
        </linearGradient>
        <linearGradient id="E" x1="107.466%" x2="9.303%" y1="107.831%" y2="3.207%">
          <stop offset="0%" stopColor="#006D3D" />
          <stop offset="100%" stopColor="#004C29" />
        </linearGradient>
        <linearGradient id="M" x1="113.177%" x2="2.151%" y1="104.673%" y2="9.713%">
          <stop offset="0%" stopColor="#008034" />
          <stop offset="100%" stopColor="#004F21" />
        </linearGradient>
        <linearGradient id="U" x1="29.468%" x2="97.963%" y1="50%" y2="50%">
          <stop offset="0%" stopColor="#F0F0F0" />
          <stop offset="100%" stopColor="#FFF" />
        </linearGradient>
        <pattern id="e" width="512" height="512" x="-512" y="-471" patternUnits="userSpaceOnUse">
          <use xlinkHref="#a" />
        </pattern>
        <pattern id="i" width="512" height="512" x="-441" y="-471" patternUnits="userSpaceOnUse">
          <use xlinkHref="#b" />
        </pattern>
        <pattern id="m" width="512" height="512" x="-512" y="-513" patternUnits="userSpaceOnUse">
          <use xlinkHref="#c" />
        </pattern>
        <pattern id="q" width="512" height="512" x="-441" y="-513" patternUnits="userSpaceOnUse">
          <use xlinkHref="#d" />
        </pattern>
        <pattern id="u" width="512" height="512" x="-512" y="-429" patternUnits="userSpaceOnUse">
          <use xlinkHref="#e" />
        </pattern>
        <pattern id="y" width="512" height="512" x="-441" y="-429" patternUnits="userSpaceOnUse">
          <use xlinkHref="#f" />
        </pattern>
        <pattern id="C" width="512" height="512" x="-512" y="-387" patternUnits="userSpaceOnUse">
          <use xlinkHref="#g" />
        </pattern>
        <pattern id="G" width="512" height="512" x="-441" y="-387" patternUnits="userSpaceOnUse">
          <use xlinkHref="#h" />
        </pattern>
        <pattern id="O" width="512" height="512" x="-512" y="-512" patternUnits="userSpaceOnUse">
          <use xlinkHref="#i" />
        </pattern>
        <radialGradient id="N" cx="86.601%" cy="84.21%" r="62.398%" fx="86.601%" fy="84.21%">
          <stop offset="0%" stopColor="#018137" />
          <stop offset="100%" stopColor="#007E35" stopOpacity="0" />
        </radialGradient>
        <polygon
          id="T"
          points="25 73.107 41.756 46.96 26.572 23 38.144 23 47.977 41.099 57.61 23 69.082 23 53.83 47.336 70.587 73.107 58.647 73.107 47.777 53.778 36.873 73.107"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(48)">
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <g mask="url(#b)">
            <use fill="url(#c)" xlinkHref="#d" />
            <use fill="url(#e)" fillOpacity=".012" xlinkHref="#d" />
            <use fill="#000" filter="url(#f)" xlinkHref="#d" />
          </g>
          <g mask="url(#b)">
            <use fill="url(#g)" xlinkHref="#h" />
            <use fill="url(#i)" fillOpacity=".012" xlinkHref="#h" />
            <use fill="#000" filter="url(#j)" xlinkHref="#h" />
          </g>
          <g mask="url(#b)">
            <use fill="url(#k)" xlinkHref="#l" />
            <use fill="url(#m)" fillOpacity=".012" xlinkHref="#l" />
            <use fill="#000" filter="url(#n)" xlinkHref="#l" />
          </g>
          <g mask="url(#b)">
            <use fill="url(#o)" xlinkHref="#p" />
            <use fill="url(#q)" fillOpacity=".012" xlinkHref="#p" />
            <use fill="#000" filter="url(#r)" xlinkHref="#p" />
          </g>
          <g mask="url(#b)">
            <use fill="url(#s)" xlinkHref="#t" />
            <use fill="url(#u)" fillOpacity=".012" xlinkHref="#t" />
            <use fill="#000" filter="url(#v)" xlinkHref="#t" />
          </g>
          <g mask="url(#b)">
            <use fill="url(#w)" xlinkHref="#x" />
            <use fill="url(#y)" fillOpacity=".012" xlinkHref="#x" />
            <use fill="#000" filter="url(#z)" xlinkHref="#x" />
          </g>
          <g mask="url(#b)">
            <use fill="url(#A)" xlinkHref="#B" />
            <use fill="url(#C)" fillOpacity=".012" xlinkHref="#B" />
            <use fill="#000" filter="url(#D)" xlinkHref="#B" />
          </g>
          <g mask="url(#b)">
            <use fill="url(#E)" xlinkHref="#F" />
            <use fill="url(#G)" fillOpacity=".012" xlinkHref="#F" />
            <use fill="#000" filter="url(#H)" xlinkHref="#F" />
          </g>
        </g>
        <g transform="translate(48)">
          <mask id="K" fill="#fff">
            <use xlinkHref="#I" />
          </mask>
          <path
            fill="#000"
            fillOpacity=".1"
            d="M-40,37 L40,37 C44.418278,37 48,40.581722 48,45 L48,125 C48,129.418278 34.418278,143 30,143 L-50,143 C-54.418278,143 -58,139.418278 -58,135 L-58,55 C-58,50.581722 -44.418278,37 -40,37 Z"
            filter="url(#J)"
            mask="url(#K)"
          />
        </g>
        <g transform="translate(0 35)">
          <mask id="P" fill="#fff">
            <use xlinkHref="#L" />
          </mask>
          <use fill="url(#M)" xlinkHref="#L" />
          <use fill="url(#N)" xlinkHref="#L" />
          <use fill="url(#O)" fillOpacity=".013" xlinkHref="#L" />
          <g fill="#000" mask="url(#P)">
            <use filter="url(#Q)" xlinkHref="#R" />
          </g>
          <g mask="url(#P)">
            <use fill="#000" filter="url(#S)" xlinkHref="#T" />
            <use fill="url(#U)" xlinkHref="#T" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Svg;
