import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getReportOrdersAction } from '@/redux/actions';
import { getReportOrders, TGetReportOrdersResponse } from '@/services/api';

// FUNCTION

export function* getReportOrdersSaga(action: ActionType<typeof getReportOrdersAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getReportOrders, materials);
    const getReportOrdersResponse: TGetReportOrdersResponse = response as TGetReportOrdersResponse;
    yield put(getReportOrdersAction.success(getReportOrdersResponse));
    successCallback?.(getReportOrdersResponse);
  } catch (err) {
    yield put(getReportOrdersAction.failure(err));
    failedCallback?.(err);
  }
}
