import { createReducer } from 'deox';

import { TGetTagsResponse } from '@/services/api/tag';
import { getTagsAction } from '@/redux/actions';
import { getTagsUpdateState } from './get-tags';

export type TTagState = {
  getTagsResponse?: TGetTagsResponse;
};

const initialState: TTagState = {
  getTagsResponse: undefined,
};

const TagReducer = createReducer(initialState, (handleAction) => [
  handleAction(getTagsAction.success, getTagsUpdateState),
]);

export default TagReducer;
