import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { addPlayerToClassAction } from '@/redux/actions';
import { addPlayerToClass, TAddPlayerToClassResponse } from '@/services/api';

// FUNCTION

export function* addPlayerToClassSaga(action: ActionType<typeof addPlayerToClassAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(addPlayerToClass, materials);
    const addPlayerToClassResponse: TAddPlayerToClassResponse = response as TAddPlayerToClassResponse;
    yield put(addPlayerToClassAction.success(addPlayerToClassResponse));
    successCallback?.(addPlayerToClassResponse);
  } catch (err) {
    yield put(addPlayerToClassAction.failure(err));
    failedCallback?.(err);
  }
}
