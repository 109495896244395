import { createActionCreator } from 'deox';

import { TGetPlayersInClassMaterials, TGetPlayersInClassResponse } from '@/services/api/player/get-players-in-class';

// CONSTANTS

export enum EGetPlayersInClassAction {
  GET_PLAYERS_IN_CLASS = 'GET_PLAYERS_IN_CLASS',
  GET_PLAYERS_IN_CLASS_REQUEST = 'GET_PLAYERS_IN_CLASS_REQUEST',
  GET_PLAYERS_IN_CLASS_SUCCESS = 'GET_PLAYERS_IN_CLASS_SUCCESS',
  GET_PLAYERS_IN_CLASS_FAILED = 'GET_PLAYERS_IN_CLASS_FAILED',
}

// TYPES

export type TGetPlayersInClassRequest = {
  type: EGetPlayersInClassAction.GET_PLAYERS_IN_CLASS_REQUEST;
  payload: {
    materials: TGetPlayersInClassMaterials;
    successCallback?: (response: TGetPlayersInClassResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetPlayersInClassSuccess = {
  type: EGetPlayersInClassAction.GET_PLAYERS_IN_CLASS_SUCCESS;
  payload: { response: TGetPlayersInClassResponse };
};

export type TGetPlayersInClassFailed = { type: EGetPlayersInClassAction.GET_PLAYERS_IN_CLASS_FAILED };

// FUNCTION

export const getPlayersInClassAction = {
  request: createActionCreator(
    EGetPlayersInClassAction.GET_PLAYERS_IN_CLASS_REQUEST,
    (resolve) =>
      (
        materials: TGetPlayersInClassMaterials,
        successCallback?: (response: TGetPlayersInClassResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetPlayersInClassRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetPlayersInClassAction.GET_PLAYERS_IN_CLASS_SUCCESS,
    (resolve) =>
      (response: TGetPlayersInClassResponse): TGetPlayersInClassSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetPlayersInClassAction.GET_PLAYERS_IN_CLASS_FAILED,
    (resolve) =>
      (error: unknown): TGetPlayersInClassFailed =>
        resolve({ error }),
  ),
};
