import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getPlayersInClassAction } from '@/redux/actions';
import { getPlayersInClass, TGetPlayersInClassResponse } from '@/services/api';

// FUNCTION

export function* getPlayersInClassSaga(action: ActionType<typeof getPlayersInClassAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getPlayersInClass, materials);
    const getPlayersInClassResponse: TGetPlayersInClassResponse = response as TGetPlayersInClassResponse;
    yield put(getPlayersInClassAction.success(getPlayersInClassResponse));
    successCallback?.(getPlayersInClassResponse);
  } catch (err) {
    yield put(getPlayersInClassAction.failure(err));
    failedCallback?.(err);
  }
}
