import { createActionCreator } from 'deox';

import {
  TGetPublicCategoriesMaterials,
  TGetPublicCategoriesResponse,
} from '@/services/api/category/get-public-categories';

// CONSTANTS

export enum EGetPublicCategoriesAction {
  GET_PUBLIC_CATEGORIES = 'GET_PUBLIC_CATEGORIES',
  GET_PUBLIC_CATEGORIES_REQUEST = 'GET_PUBLIC_CATEGORIES_REQUEST',
  GET_PUBLIC_CATEGORIES_SUCCESS = 'GET_PUBLIC_CATEGORIES_SUCCESS',
  GET_PUBLIC_CATEGORIES_FAILED = 'GET_PUBLIC_CATEGORIES_FAILED',
}

// TYPES

export type TGetPublicCategoriesRequest = {
  type: EGetPublicCategoriesAction.GET_PUBLIC_CATEGORIES_REQUEST;
  payload: {
    materials: TGetPublicCategoriesMaterials;
    successCallback?: (response: TGetPublicCategoriesResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetPublicCategoriesSuccess = {
  type: EGetPublicCategoriesAction.GET_PUBLIC_CATEGORIES_SUCCESS;
  payload: { response: TGetPublicCategoriesResponse };
};

export type TGetPublicCategoriesFailed = { type: EGetPublicCategoriesAction.GET_PUBLIC_CATEGORIES_FAILED };

// FUNCTION

export const getPublicCategoriesAction = {
  request: createActionCreator(
    EGetPublicCategoriesAction.GET_PUBLIC_CATEGORIES_REQUEST,
    (resolve) =>
      (
        materials: TGetPublicCategoriesMaterials,
        successCallback?: (response: TGetPublicCategoriesResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetPublicCategoriesRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetPublicCategoriesAction.GET_PUBLIC_CATEGORIES_SUCCESS,
    (resolve) =>
      (response: TGetPublicCategoriesResponse): TGetPublicCategoriesSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetPublicCategoriesAction.GET_PUBLIC_CATEGORIES_FAILED,
    (resolve) =>
      (error: unknown): TGetPublicCategoriesFailed =>
        resolve({ error }),
  ),
};
