import { TUser } from '@/common/models';
import { THeaderBranchIds, TCommonResponse, TCommonPaginate } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetPlayersInClassParams = {
  page: number;
  size: number;
  name?: string;
  sort?: string;
  classIds?: string;
  auditingStatuses?: string;
};

export type TGetPlayersInClassMaterials = {
  params?: TGetPlayersInClassParams;
  headers?: THeaderBranchIds;
};

export type TGetPlayersInClassResponse = TCommonResponse & {
  data: TCommonPaginate & {
    content: TUser[];
  };
};

// FUNCTION

export const getPlayersInClass = async ({
  params,
}: TGetPlayersInClassMaterials): Promise<TGetPlayersInClassResponse> => {
  const response = await ApiService.get(`/v1/api/admin/players`, { params });
  return response.data;
};
