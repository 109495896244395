export enum EIconName {
  Google = 'Google',
  ArrowLongRight = 'ArrowLongRight',
  ArrowLongLeft = 'ArrowLongLeft',
  AngleDown = 'AngleDown',
  X = 'X',
  StarOutline = 'StarOutline',
  Check = 'Check',
  QuestionCircle = 'QuestionCircle',
  Dashboard = 'Dashboard',
  School = 'School',
  CreditCard = 'CreditCard',
  TruckDelivery = 'TruckDelivery',
  ShoppingCart = 'ShoppingCart',
  Database = 'Database',
  ReportAnalytics = 'ReportAnalytics',
  Settings = 'Settings',
  GitBranch = 'GitBranch',
  Users = 'Users',
  ChalkBoard = 'ChalkBoard',
  Rocket = 'Rocket',
  Calendar = 'Calendar',
  SpeakerPhone = 'SpeakerPhone',
  ClockCancel = 'ClockCancel',
  Wallet = 'Wallet',
  WalletOff = 'WalletOff',
  Map = 'Map',
  Checkbox = 'Checkbox',
  Affiliate = 'Affiliate',
  Category = 'Category',
  ShopProducts = 'ShopProduct',
  BoxSeam = 'BoxSeam',
  Award = 'Award',
  Receipt = 'Receipt',
  ReceiptRefund = 'ReceiptRefund',
  WareHouse = 'WareHouse',
  PackageImport = 'PackageImport',
  Graph = 'Graph',
  Adjustments = 'Adjustments',
  UsersGroup = 'UsersGroup',
  BusinessPlan = 'BusinessPlan',
  CaretDown = 'CaretDown',
  MapMarker = 'MapMarker',
  AddressBook = 'AddressBook',
  Logout = 'Logout',
  DotsVertical = 'DotsVertical',
  Trash = 'Trash',
  Pencil = 'Pencil',
  Search = 'Search',
  Plus = 'Plus',
  AngleLeft = 'AngleLeft',
  AngleRight = 'AngleRight',
  PigMoney = 'PigMoney',
  Coins = 'Coins',
  Eye = 'Eye',
  Clock = 'Clock',
  Copy = 'Copy',
  UserCheck = 'UserCheck',
  UserCancel = 'UserCancel',
  UserX = 'UserX',
  DeviceFloppy = 'DeviceFloppy',
  Devices = 'Devices',
  Lock = 'Lock',
  ArrowExchange = 'ArrowExchange',
  JewishStarFill = 'JewishStarFill',
  JewishStar = 'JewishStar',
  RotateClockwise = 'RotateClockwise',
  Briefcase = 'Briefcase',
  ShieldCancel = 'ShieldCancel',
  ArrowBigUpLines = 'ArrowBigUpLines',
  Pdf = 'Pdf',
  Ticket = 'Ticket',
  ArrowLeftRight = 'ArrowLeftRight',
  MoneyBag = 'MoneyBag',
  Excel = 'Excel',
  GripVertical = 'GripVertical',
}

export enum EIconColor {
  BLACK = '#000',
  WHITE = '#fff',
  PURPLE_HEART = '#673AB7',
  CERULEAN = '#03A9F4',
  TUNDORA = '#454545',
  MINE_SHAFT = '#1F1F1F',
  SILVER = '#CCCCCC',
  SILVER_CHALICE = '#AAAAAA',
  APPLE = '#43A047',
  POMEGRANATE = '#F44336',
  DOVE_GRAY = '#6E6E6E',
  AMBER = '#FFC107',
  SELECTIVE_YELLOW = '#FFB300',
}
