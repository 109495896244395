import { createActionCreator } from 'deox';

import { TAddPlayerToClassMaterials, TAddPlayerToClassResponse } from '@/services/api/class/add-player-to-class';

// CONSTANTS

export enum EAddPlayerToClassAction {
  ADD_PLAYER_TO_CLASS = 'ADD_PLAYER_TO_CLASS',
  ADD_PLAYER_TO_CLASS_REQUEST = 'ADD_PLAYER_TO_CLASS_REQUEST',
  ADD_PLAYER_TO_CLASS_SUCCESS = 'ADD_PLAYER_TO_CLASS_SUCCESS',
  ADD_PLAYER_TO_CLASS_FAILED = 'ADD_PLAYER_TO_CLASS_FAILED',
}

// TYPES

export type TAddPlayerToClassRequest = {
  type: EAddPlayerToClassAction.ADD_PLAYER_TO_CLASS_REQUEST;
  payload: {
    materials: TAddPlayerToClassMaterials;
    successCallback?: (response: TAddPlayerToClassResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TAddPlayerToClassSuccess = {
  type: EAddPlayerToClassAction.ADD_PLAYER_TO_CLASS_SUCCESS;
  payload: { response: TAddPlayerToClassResponse };
};

export type TAddPlayerToClassFailed = { type: EAddPlayerToClassAction.ADD_PLAYER_TO_CLASS_FAILED };

// FUNCTION

export const addPlayerToClassAction = {
  request: createActionCreator(
    EAddPlayerToClassAction.ADD_PLAYER_TO_CLASS_REQUEST,
    (resolve) =>
      (
        materials: TAddPlayerToClassMaterials,
        successCallback?: (response: TAddPlayerToClassResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TAddPlayerToClassRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EAddPlayerToClassAction.ADD_PLAYER_TO_CLASS_SUCCESS,
    (resolve) =>
      (response: TAddPlayerToClassResponse): TAddPlayerToClassSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EAddPlayerToClassAction.ADD_PLAYER_TO_CLASS_FAILED,
    (resolve) =>
      (error: unknown): TAddPlayerToClassFailed =>
        resolve({ error }),
  ),
};
