import { TClassState } from '@/redux/reducers/class';
import { TRemovePlayerFromClassSuccess } from '@/redux/actions/class';

export const removePlayerFromClassUpdateState = (
  state: TClassState,
  action: TRemovePlayerFromClassSuccess,
): TClassState => ({
  ...state,
  removePlayerFromClassResponse: action.payload.response,
});
