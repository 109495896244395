import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { removePlayerFromClassAction } from '@/redux/actions';
import { removePlayerFromClass, TRemovePlayerFromClassResponse } from '@/services/api';

// FUNCTION

export function* removePlayerFromClassSaga(action: ActionType<typeof removePlayerFromClassAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(removePlayerFromClass, materials);
    const removePlayerFromClassResponse: TRemovePlayerFromClassResponse = response as TRemovePlayerFromClassResponse;
    yield put(removePlayerFromClassAction.success(removePlayerFromClassResponse));
    successCallback?.(removePlayerFromClassResponse);
  } catch (err) {
    yield put(removePlayerFromClassAction.failure(err));
    failedCallback?.(err);
  }
}
