import { TPlayerState } from '@/redux/reducers/player';
import { TUpdatePlayerMemberCardSuccess } from '@/redux/actions/player';

export const updatePlayerMemberCardUpdateState = (
  state: TPlayerState,
  action: TUpdatePlayerMemberCardSuccess,
): TPlayerState => ({
  ...state,
  updatePlayerMemberCardResponse: action.payload.response,
});
