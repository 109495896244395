import { TTimeOffState } from '@/redux/reducers/time-off';
import { TRequestTimeOffStatusSuccess } from '@/redux/actions/time-off';

export const requestTimeOffStatusUpdateState = (
  state: TTimeOffState,
  action: TRequestTimeOffStatusSuccess,
): TTimeOffState => ({
  ...state,
  requestTimeOffStatusResponse: action.payload.response,
});
