import { createActionCreator } from 'deox';

import { TGetTagsMaterials, TGetTagsResponse } from '@/services/api/tag/get-tags';

// CONSTANTS

export enum EGetTagsAction {
  GET_TAGS = 'GET_TAGS',
  GET_TAGS_REQUEST = 'GET_TAGS_REQUEST',
  GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS',
  GET_TAGS_FAILED = 'GET_TAGS_FAILED',
}

// TYPES

export type TGetTagsRequest = {
  type: EGetTagsAction.GET_TAGS_REQUEST;
  payload: {
    materials: TGetTagsMaterials;
    successCallback?: (response: TGetTagsResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetTagsSuccess = {
  type: EGetTagsAction.GET_TAGS_SUCCESS;
  payload: { response: TGetTagsResponse };
};

export type TGetTagsFailed = { type: EGetTagsAction.GET_TAGS_FAILED };

// FUNCTION

export const getTagsAction = {
  request: createActionCreator(
    EGetTagsAction.GET_TAGS_REQUEST,
    (resolve) =>
      (
        materials: TGetTagsMaterials,
        successCallback?: (response: TGetTagsResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetTagsRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetTagsAction.GET_TAGS_SUCCESS,
    (resolve) =>
      (response: TGetTagsResponse): TGetTagsSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetTagsAction.GET_TAGS_FAILED,
    (resolve) =>
      (error: unknown): TGetTagsFailed =>
        resolve({ error }),
  ),
};
