import { createActionCreator } from 'deox';

import {
  TGetReportBenefitLossMaterials,
  TGetReportBenefitLossResponse,
} from '@/services/api/report/get-report-benefit-loss';

// CONSTANTS

export enum EGetReportBenefitLossAction {
  GET_REPORT_BENEFIT_LOSS = 'GET_REPORT_BENEFIT_LOSS',
  GET_REPORT_BENEFIT_LOSS_REQUEST = 'GET_REPORT_BENEFIT_LOSS_REQUEST',
  GET_REPORT_BENEFIT_LOSS_SUCCESS = 'GET_REPORT_BENEFIT_LOSS_SUCCESS',
  GET_REPORT_BENEFIT_LOSS_FAILED = 'GET_REPORT_BENEFIT_LOSS_FAILED',
}

// TYPES

export type TGetReportBenefitLossRequest = {
  type: EGetReportBenefitLossAction.GET_REPORT_BENEFIT_LOSS_REQUEST;
  payload: {
    materials: TGetReportBenefitLossMaterials;
    successCallback?: (response: TGetReportBenefitLossResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetReportBenefitLossSuccess = {
  type: EGetReportBenefitLossAction.GET_REPORT_BENEFIT_LOSS_SUCCESS;
  payload: { response: TGetReportBenefitLossResponse };
};

export type TGetReportBenefitLossFailed = { type: EGetReportBenefitLossAction.GET_REPORT_BENEFIT_LOSS_FAILED };

// FUNCTION

export const getReportBenefitLossAction = {
  request: createActionCreator(
    EGetReportBenefitLossAction.GET_REPORT_BENEFIT_LOSS_REQUEST,
    (resolve) =>
      (
        materials: TGetReportBenefitLossMaterials,
        successCallback?: (response: TGetReportBenefitLossResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetReportBenefitLossRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetReportBenefitLossAction.GET_REPORT_BENEFIT_LOSS_SUCCESS,
    (resolve) =>
      (response: TGetReportBenefitLossResponse): TGetReportBenefitLossSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetReportBenefitLossAction.GET_REPORT_BENEFIT_LOSS_FAILED,
    (resolve) =>
      (error: unknown): TGetReportBenefitLossFailed =>
        resolve({ error }),
  ),
};
