import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getPublicCategoriesAction } from '@/redux/actions';
import { getPublicCategories, TGetPublicCategoriesResponse } from '@/services/api';

// FUNCTION

export function* getPublicCategoriesSaga(action: ActionType<typeof getPublicCategoriesAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getPublicCategories, materials);
    const getPublicCategoriesResponse: TGetPublicCategoriesResponse = response as TGetPublicCategoriesResponse;
    yield put(getPublicCategoriesAction.success(getPublicCategoriesResponse));
    successCallback?.(getPublicCategoriesResponse);
  } catch (err) {
    yield put(getPublicCategoriesAction.failure(err));
    failedCallback?.(err);
  }
}
