import { createReducer } from 'deox';

import {
  TGetReportAttendancesResponse,
  TGetReportBenefitLossResponse,
  TGetReportExpensesResponse,
  TGetReportInventoriesResponse,
  TGetReportOrdersResponse,
  TGetReportRevenuesResponse,
} from '@/services/api/report';
import {
  getReportAttendancesAction,
  getReportBenefitLossAction,
  getReportExpensesAction,
  getReportInventoriesAction,
  getReportOrdersAction,
  getReportRevenuesAction,
} from '@/redux/actions';
import { getReportAttendancesUpdateState } from './get-report-attendances';
import { getReportBenefitLossUpdateState } from './get-report-benefit-loss';
import { getReportExpensesUpdateState } from './get-report-expenses';
import { getReportInventoriesUpdateState } from './get-report-inventories';
import { getReportOrdersUpdateState } from './get-report-orders';
import { getReportRevenuesUpdateState } from './get-report-revenues';

export type TReportState = {
  getReportAttendancesResponse?: TGetReportAttendancesResponse;
  getReportBenefitLossResponse?: TGetReportBenefitLossResponse;
  getReportExpensesResponse?: TGetReportExpensesResponse;
  getReportInventoriesResponse?: TGetReportInventoriesResponse;
  getReportOrdersResponse?: TGetReportOrdersResponse;
  getReportRevenuesResponse?: TGetReportRevenuesResponse;
};

const initialState: TReportState = {
  getReportAttendancesResponse: undefined,
  getReportBenefitLossResponse: undefined,
  getReportExpensesResponse: undefined,
  getReportInventoriesResponse: undefined,
  getReportOrdersResponse: undefined,
  getReportRevenuesResponse: undefined,
};

const ReportReducer = createReducer(initialState, (handleAction) => [
  handleAction(getReportAttendancesAction.success, getReportAttendancesUpdateState),
  handleAction(getReportBenefitLossAction.success, getReportBenefitLossUpdateState),
  handleAction(getReportExpensesAction.success, getReportExpensesUpdateState),
  handleAction(getReportInventoriesAction.success, getReportInventoriesUpdateState),
  handleAction(getReportOrdersAction.success, getReportOrdersUpdateState),
  handleAction(getReportRevenuesAction.success, getReportRevenuesUpdateState),
]);

export default ReportReducer;
