import { createActionCreator } from 'deox';

import {
  TRequestTimeOffStatusMaterials,
  TRequestTimeOffStatusResponse,
} from '@/services/api/time-off/request-time-off-status';

// CONSTANTS

export enum ERequestTimeOffStatusAction {
  REQUEST_TIME_OFF_STATUS = 'REQUEST_TIME_OFF_STATUS',
  REQUEST_TIME_OFF_STATUS_REQUEST = 'REQUEST_TIME_OFF_STATUS_REQUEST',
  REQUEST_TIME_OFF_STATUS_SUCCESS = 'REQUEST_TIME_OFF_STATUS_SUCCESS',
  REQUEST_TIME_OFF_STATUS_FAILED = 'REQUEST_TIME_OFF_STATUS_FAILED',
}

// TYPES

export type TRequestTimeOffStatusRequest = {
  type: ERequestTimeOffStatusAction.REQUEST_TIME_OFF_STATUS_REQUEST;
  payload: {
    materials: TRequestTimeOffStatusMaterials;
    successCallback?: (response: TRequestTimeOffStatusResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TRequestTimeOffStatusSuccess = {
  type: ERequestTimeOffStatusAction.REQUEST_TIME_OFF_STATUS_SUCCESS;
  payload: { response: TRequestTimeOffStatusResponse };
};

export type TRequestTimeOffStatusFailed = { type: ERequestTimeOffStatusAction.REQUEST_TIME_OFF_STATUS_FAILED };

// FUNCTION

export const requestTimeOffStatusAction = {
  request: createActionCreator(
    ERequestTimeOffStatusAction.REQUEST_TIME_OFF_STATUS_REQUEST,
    (resolve) =>
      (
        materials: TRequestTimeOffStatusMaterials,
        successCallback?: (response: TRequestTimeOffStatusResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TRequestTimeOffStatusRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ERequestTimeOffStatusAction.REQUEST_TIME_OFF_STATUS_SUCCESS,
    (resolve) =>
      (response: TRequestTimeOffStatusResponse): TRequestTimeOffStatusSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ERequestTimeOffStatusAction.REQUEST_TIME_OFF_STATUS_FAILED,
    (resolve) =>
      (error: unknown): TRequestTimeOffStatusFailed =>
        resolve({ error }),
  ),
};
