import { TCategoryState } from '@/redux/reducers/category';
import { TGetPublicCategoriesSuccess } from '@/redux/actions/category';

export const getPublicCategoriesUpdateState = (
  state: TCategoryState,
  action: TGetPublicCategoriesSuccess,
): TCategoryState => ({
  ...state,
  getPublicCategoriesResponse: action.payload.response,
});
