import { TCommonResponse, THeaderBranchIds } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetReportOrdersParams = {
  filterUnit?: string;
  fromDate?: number;
  toDate?: number;
};

export type TGetReportOrdersMaterials = {
  params?: TGetReportOrdersParams;
  headers?: THeaderBranchIds;
};

export type TOrderItemPerTimeList = { quantity: number; at_date: number; at_month: number; at_year: number };

export type TReportOrdersData = {
  order_item_per_time_list: TOrderItemPerTimeList[];
  product_id: number;
  total_quantity: number;
  product_description: string;
  product_image: string;
  product_name: string;
};

export type TGetReportOrdersResponse = TCommonResponse & {
  data?: TReportOrdersData[];
};

// FUNCTION

export const getReportOrders = async ({
  params,
  headers,
}: TGetReportOrdersMaterials): Promise<TGetReportOrdersResponse> => {
  const response = await ApiService.get(`/v1/api/orders/${params?.filterUnit}`, { params, headers });
  return response.data;
};
